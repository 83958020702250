import React, { Suspense, useEffect } from 'react';
import { Canvas, useLoader, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import { TextureLoader } from 'three';

interface ModelProps {
    url: string;
}

const Model: React.FC<ModelProps> = ({ url }) => {
    const { scene } = useGLTF(url);

    return (
        <group position={[0, -2.0, 0]} scale={[2.5, 2.5, 2.5]}>
            <primitive object={scene} />
        </group>
    );
};

interface ModelViewerProps {
    modelUrl: string;
}

const ModelViewer: React.FC<ModelViewerProps> = ({ modelUrl }) => {
    return (
        <Canvas style={{ width: '100vw', height: '100vh' }}>
            {/* Increase ambient light intensity */}
            <ambientLight intensity={1.5} />

            {/* Increase directional light intensity */}
            <directionalLight position={[10, 10, 5]} intensity={2} />

            {/* Optionally add more directional lights or other types of lights */}
            <Suspense fallback={null}>
                <Model url={modelUrl} />
            </Suspense>
            <OrbitControls
                enablePan={false}
                enableZoom={false}
                minPolarAngle={Math.PI / 2}
                maxPolarAngle={Math.PI / 2}
            />
        </Canvas>
    );
};

export default ModelViewer;
